import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Selamat datang di `}<a parentName="p" {...{
        "href": "http://bisacoding.id"
      }}>{`Bisacoding.id`}</a>{`,`}</p>
    <p>{`Mulai perjalananmu dengan meng-akses sumber pembelajaran `}<em parentName="p">{`coding`}</em>{`-mu di `}<strong parentName="p">{`bisacoding`}</strong>{` !`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      