import React from "react";

export const Video = () => {
  const data = [
    {
      url: "https://www.youtube.com/embed/b8dV7DPAQ6U?controls=0",
      label: "Tutorial React #1: install & Component",
    },
    {
      url: "https://www.youtube.com/embed/O9lGLWnUwBs?controls=0",
      label: "Tutorial React #2: Props & Parent State",
    },
    {
      url: "https://www.youtube.com/embed/DqXitsBFU_c?controls=0",
      label: "Tutorial React #3: React Router",
    },
    {
      url: "https://www.youtube.com/embed/5OOa9Mo7YDM?controls=0",
      label: "Tutorial React #4.1: Axios",
    },
    {
      url: "https://www.youtube.com/embed/ddzBrweNgLA?controls=0",
      label: "Tutorial React #4.2: Axios form submit",
    },
    {
      url: "https://www.youtube.com/embed/VN7B8pKCNs0?controls=0",
      label: "Tutorial React #5.1: Redux Basic",
    },
  ];

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "space-between",
      }}
    >
      {data.map((d) => (
        <div
          style={{
            marginBottom: "2em",
            marginLeft: "10px",
            marginRight: "10px",
          }}
        >
          <iframe
            width="280"
            height="157"
            src={d.url}
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
          <br />
          {d.label}
        </div>
      ))}
    </div>
  );
};

export default Video;
